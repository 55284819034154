<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import { mapState } from "vuex";

export default {
  locales: {
    pt: {
    },
    es: {
    },
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  mounted: function () {
    document.body.setAttribute("data-sidebar", "light");
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4) parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) parent5.classList.add("mm-active");
          }
        }
      }
    }
  },
  methods: {
  },
};
</script>

<template>
  <div id="sidebar-menu" class="pt-3">
    <a href="https://liderleads.com/" target="_blank" class="text-dark">
      <div class="card bg-soft-warning rounded border-0 mb-1">
        <div class="card-body py-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
            style="fill: rgba(0, 0, 0, 1);transform: rotate(90deg);msFilter:progid:DXImageTransform.Microsoft.BasicImage(rotation=1);">
            <path d="M16 13h-6v-3l-5 4 5 4v-3h7a1 1 0 0 0 1-1V5h-2v8z"></path>
          </svg>
          <span class="notranslate ml-1">Ir para o site</span>
        </div>
      </div>
    </a>

    <ul id="side-menu" class="metismenu list-unstyled">
      <li>
        <router-link tag="a" to="/" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
          <span class="notranslate">Painel</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/plans" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path
              d="M22 8a.76.76 0 0 0 0-.21v-.08a.77.77 0 0 0-.07-.16.35.35 0 0 0-.05-.08l-.1-.13-.08-.06-.12-.09-9-5a1 1 0 0 0-1 0l-9 5-.09.07-.11.08a.41.41 0 0 0-.07.11.39.39 0 0 0-.08.1.59.59 0 0 0-.06.14.3.3 0 0 0 0 .1A.76.76 0 0 0 2 8v8a1 1 0 0 0 .52.87l9 5a.75.75 0 0 0 .13.06h.1a1.06 1.06 0 0 0 .5 0h.1l.14-.06 9-5A1 1 0 0 0 22 16V8zm-10 3.87L5.06 8l2.76-1.52 6.83 3.9zm0-7.72L18.94 8 16.7 9.25 9.87 5.34zM4 9.7l7 3.92v5.68l-7-3.89zm9 9.6v-5.68l3-1.68V15l2-1v-3.18l2-1.11v5.7z">
            </path>
          </svg>
          <span class="notranslate">Meu Plano</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/tools" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M8.293 6.293 2.586 12l5.707 5.707 1.414-1.414L5.414 12l4.293-4.293zm7.414 11.414L21.414 12l-5.707-5.707-1.414 1.414L18.586 12l-4.293 4.293z"></path>
          </svg>
          <span class="notranslate">Ferramentas</span>
        </router-link>
      </li>
      <li>
        <a class="is-parent has-arrow" href="javascript:void(0);">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M12 10c1.151 0 2-.848 2-2s-.849-2-2-2c-1.15 0-2 .848-2 2s.85 2 2 2zm0 1c-2.209 0-4 1.612-4 3.6v.386h8V14.6c0-1.988-1.791-3.6-4-3.6z"></path>
            <path d="M19 2H5c-1.103 0-2 .897-2 2v13c0 1.103.897 2 2 2h4l3 3 3-3h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zm-5 15-2 2-2-2H5V4h14l.002 13H14z"></path>
          </svg>
          <span class="notranslate">Rede</span>
        </a>
        <ul class="sub-menu">
          <li>
            <router-link tag="a" to="/network/sponsored" class="side-nav-link-ref">
              <span class="notranslate">Indicados</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/network/team" class="side-nav-link-ref">
              <span class="notranslate">Equipe</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/network/binary" class="side-nav-link-ref">
              <span class="notranslate">Binário</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <a class="is-parent has-arrow" href="javascript:void(0);">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 21h14c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zM5 5h14l.001 14H5V5z"></path>
            <path d="m13.553 11.658-4-2-2.448 4.895 1.79.894 1.552-3.105 4 2 2.448-4.895-1.79-.894z"></path>
          </svg>
          <span class="notranslate">Bonificações</span>
        </a>
        <ul class="sub-menu">
          <li>
            <router-link tag="a" to="/reports/indicacao" class="side-nav-link-ref">
              <span class="notranslate">Indicação</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/reports/equiparacao" class="side-nav-link-ref">
              <span class="notranslate">Equiparação</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/reports/residual" class="side-nav-link-ref">
              <span class="notranslate">Residual</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <router-link tag="a" to="/wallet/extract" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M19 2H5c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM5 20V4h14l.001 16H5z">
            </path>
            <path d="M7 12h2v2H7zm0 4h2v2H7zm4-4h2v2h-2zM7 6h10v4H7zm4 10h2v2h-2zm4-4h2v6h-2z"></path>
          </svg>
          <span class="notranslate">Financeiro</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/informatives" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path
              d="M19.875 3H4.125C2.953 3 2 3.897 2 5v14c0 1.103.953 2 2.125 2h15.75C21.047 21 22 20.103 22 19V5c0-1.103-.953-2-2.125-2zm0 16H4.125c-.057 0-.096-.016-.113-.016-.007 0-.011.002-.012.008L3.988 5.046c.007-.01.052-.046.137-.046h15.75c.079.001.122.028.125.008l.012 13.946c-.007.01-.052.046-.137.046z">
            </path>
            <path d="M6 7h6v6H6zm7 8H6v2h12v-2h-4zm1-4h4v2h-4zm0-4h4v2h-4z"></path>
          </svg>
          <span class="notranslate">Informativos</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/events" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M7 11h2v2H7zm0 4h2v2H7zm4-4h2v2h-2zm0 4h2v2h-2zm4-4h2v2h-2zm0 4h2v2h-2z"></path>
            <path d="M5 22h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zM19 8l.001 12H5V8h14z"></path>
          </svg>
          <span class="notranslate">Eventos</span>
        </router-link>
      </li>
      <li>
        <a class="is-parent has-arrow" href="javascript:void(0);">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path
              d="M12 6a3.939 3.939 0 0 0-3.934 3.934h2C10.066 8.867 10.934 8 12 8s1.934.867 1.934 1.934c0 .598-.481 1.032-1.216 1.626a9.208 9.208 0 0 0-.691.599c-.998.997-1.027 2.056-1.027 2.174V15h2l-.001-.633c.001-.016.033-.386.441-.793.15-.15.339-.3.535-.458.779-.631 1.958-1.584 1.958-3.182A3.937 3.937 0 0 0 12 6zm-1 10h2v2h-2z">
            </path>
            <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path>
          </svg>
          <span class="notranslate">Suporte</span>
        </a>
        <ul class="sub-menu">
          <li>
            <router-link tag="a" to="/downloads" class="side-nav-link-ref">
              <span class="notranslate">Downloads</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/faq" class="side-nav-link-ref">
              <span class="notranslate">FAQ</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/tickets" class="side-nav-link-ref">
              <span class="notranslate">Atendimento</span>
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.py-2 {
  padding: 0.9rem 1.5rem 0.7rem !important;
}
.py-2 i,
.py-2 span {
  line-height: 0px !important;
  padding-bottom: 0 !important;
}
.py-2 i {
  margin-top: -4px;
}
</style>